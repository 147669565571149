import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";

function DesignPLPHero({ props }) {
  const designProps = props;

  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - PLP Hero" />
      <h2>PLP Hero</h2>
      <p className="design__text p2">
        This hero component is found at the top of all Product Listing pages.
      </p>
      <div className="design__stories">
        <DesignStory
          title="PLP Hero"
          component={MediaBlock}
          type="media"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignPLPHero.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignPLPHero.defaultProps = {
  props: {},
};

export default DesignPLPHero;
